import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["projectNav", "section"]

  active(event) {
    this.projectNavTargets.forEach((item) => {
      item.classList.remove("active");
    });

    event.currentTarget.classList.add("active");

    const sectionId = event.currentTarget.dataset.section;

    this.sectionTargets.forEach((section) => {
      if (section.id === sectionId) {
        section.classList.remove("hidden");
      } else {
        section.classList.add("hidden");
      }
    });
  }
}
