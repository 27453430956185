import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "zone", "display", "filename", "filesize"];

  connect() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.zoneTarget.addEventListener(eventName, this.preventDefaults, false)
    });

    ['dragenter', 'dragover'].forEach(eventName => {
      this.zoneTarget.addEventListener(eventName, () => this.zoneTarget.classList.add('highlight'), false);
    });

    ['dragleave', 'drop'].forEach(eventName => {
      this.zoneTarget.addEventListener(eventName, () => this.zoneTarget.classList.remove('highlight'), false);
    })

    this.zoneTarget.addEventListener('drop', this.handleDrop.bind(this), false)
    this.zoneTarget.addEventListener('click', this.handleClick.bind(this), false)
    this.inputTarget.addEventListener('change', this.handleInputChange.bind(this), false)
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDrop(e) {
    let files = e.dataTransfer.files
    this.handleFiles(files)
  }

  handleClick(e) {
    e.preventDefault()
    this.inputTarget.click()
  }

  handleInputChange(e) {
    let files = e.target.files
    this.handleFiles(files)
  }

  handleFiles(files) {
    const dataTransfer = new DataTransfer()
    let file = files[0]
    let filename = file.name
    let filesize = `${(file.size / (1024)).toFixed(1)} KB`
    dataTransfer.items.add(files[0])
    this.inputTarget.files = dataTransfer.files
    this.displayTarget.classList.remove('hidden')
    this.filenameTarget.textContent = filename
    this.filesizeTarget.textContent = filesize
    this.zoneTarget.classList.add('hidden')
    this.element.closest('form').querySelector('input[type="submit"]').disabled = false
  }
}
