import { Controller } from "@hotwired/stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = ["canvas"];
  static values = {
    title: { type: String, default: '0' },
    subtitle: String
  }

  connect() {
    this.canvasTargets.forEach(canvas => {
      const centertext = canvas.dataset.doughnutChartTitle
      const subtitle = canvas.dataset.doughnutChartSubtitle
      const dataset = JSON.parse(canvas.dataset.doughnutChartDataset);
      // Convert percentage strings like "12.5%" into numbers
      dataset.forEach((set) => {
        set.data = set.data.map(value =>
          typeof value === 'string' && value.endsWith('%')
            ? parseFloat(value.replace('%', ''))
            : value
        ); });
      this.initializeChart(canvas, dataset, centertext, subtitle);
    });
  }

  initializeChart(canvas, dataset, centertext, subtitle) {
    const ctx = canvas.getContext('2d');

    // this is to add text to the center of the doughnut
    const centerTextPlugin = {
      id: 'centerText',
      beforeDatasetsDraw(chart, args, pluginOptions) {
        const { ctx, data } = chart;

        ctx.save();
        const xCoor = chart.getDatasetMeta(0).data[0].x;
        const yCoor = chart.getDatasetMeta(0).data[0].y;
        ctx.font = 'bold 32px Arial';
        ctx.fillStyle = '#1E293B';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(centertext, xCoor, yCoor);
        ctx.font = '10px Arial';
        ctx.fillText(subtitle, xCoor, yCoor + 20)      }
    };
    const myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: dataset
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.dataset.labels[tooltipItem.dataIndex];
              }
            }
          },
          legend: {
            position: 'bottom',
            display: true,
            labels: {
              // use this to make the lables circular instead of rectangles
              usePointStyle: true,
              generateLabels: function(chart) {
                let labels = [];
                chart.data.datasets.forEach((dataset, datasetIndex) => {
                  dataset.data.forEach((data, index) => {
                    labels.push({
                      text: dataset.labels[index],
                      fillStyle: dataset.backgroundColor[index],
                      strokeStyle: dataset.backgroundColor[index],
                      datasetIndex: datasetIndex,
                      index: index
                    });
                  });
                });
                return labels;
              }
            },
            onClick: function(e, legendItem) {
              const chart = this.chart;
              const datasetIndex = legendItem.datasetIndex;
              const index = legendItem.index;

              const meta = chart.getDatasetMeta(datasetIndex);
              meta.data[index].hidden = !meta.data[index].hidden;
              chart.update();
            }
          },

        }
      },
      plugins: [centerTextPlugin]
    });
  }
}
